<template>
	<ion-app>
		<ion-split-pane content-id="main-content">

			<ion-menu content-id="main-content" type="overlay">
				<ion-content>
				</ion-content>
			</ion-menu>
			<ion-router-outlet id="main-content"></ion-router-outlet>
		</ion-split-pane>
	</ion-app>
</template>

<style scoped>
ion-menu ion-content {
	--background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
	--padding-start: 8px;
	--padding-end: 8px;
	--padding-top: 20px;
	--padding-bottom: 20px;
}
</style>
