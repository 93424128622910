import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/request-verification'
  },
  {
    path: '/request-verification',
    component: () => import ('../views/request-verification.vue')
  },
  {
    path: '/verification-finished',
    component: () => import ('../views/verification-finished.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
